import React, { useState } from 'react';
import SyntaxHighlighter from 'react-syntax-highlighter';
import { nightOwl } from 'react-syntax-highlighter/dist/esm/styles/hljs';
import { CopyToClipboard } from 'react-copy-to-clipboard';


export default function BlogSanitizer() {

    const [unsanitizedCode, setUnsanitizedCode] = useState('');
    const [sanitizedCode, setSanitizedCode] = useState('');
    const [copied, setCopied] = useState(false);

    const sanitizeCode = () => {
        // eslint-disable-next-line
        const regex = /style\=\".*?\"/ig;
        setSanitizedCode(unsanitizedCode.replaceAll(regex, ''));
    }

    return (

        <div>
            <div className="md:grid md:grid-cols-3 md:gap-6 md:pt-20">
                <div className="md:col-span-1">
                    <div className="px-4 sm:px-0">
                        <h3 className="text-lg font-medium leading-6 text-gray-900">Unsanitized Code</h3>
                        <p className="mt-1 text-sm text-gray-600">Paste here the code from HubSpot. Make sure you are pasting the source code.</p>
                    </div>
                </div>
                <div className="mt-5 md:mt-0 md:col-span-2">

                    <div className="shadow sm:rounded-md sm:overflow-hidden">
                        <div className="px-4 py-5 bg-white space-y-6 sm:p-6">
                            <div>
                                <div className="mt-1">
                                    <textarea onChange={e => setUnsanitizedCode(e.target.value)} rows="10" className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 mt-1 block w-full sm:text-sm border border-gray-300 rounded-md" >{unsanitizedCode}</textarea>
                                </div>
                            </div>
                        </div>
                        <div className="px-4 py-3 bg-gray-50 text-right sm:px-6">
                            <button onClick={sanitizeCode} className="inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">Sanitize</button>

                        </div>
                    </div>
                </div>
            </div>
            {
                sanitizedCode !== '' &&
                <div>
                    <CopyToClipboard text={sanitizedCode} onCopy={() => setCopied(true)}>
                        <button className="inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">Copy to clipboard</button>
                    </CopyToClipboard>
                    {copied && <p>Copied</p>}
                    <SyntaxHighlighter language="html" style={nightOwl} wrapLines>
                        {sanitizedCode}
                    </SyntaxHighlighter>
                </div>

            }
        </div>


    );
}