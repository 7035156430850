import React from 'react';
import ABTestSingle from '../components/ABTestSingle';
import PageInfo from '../components/PageInfo';
export default function ABTestPage(props) {
    return (
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:pt-16">
            <PageInfo title={props.title} subtitle={props.subtitle} />
            <ABTestSingle isLandingPage={props.isLandingPage} />
        </div>
    );
}