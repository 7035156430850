import React from 'react';
import ABTestsOverview from '../components/ABTestOverview';
import PageInfo from '../components/PageInfo';
export default function ABTestsPage(props) {
    return (
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:pt-16">
            <PageInfo title={props.title} subtitle={props.subtitle} />
            <ABTestsOverview isLandingPage={props.isLandingPage} />
        </div>
    );
}