import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import Fetch from '../../api/Fetch';

export default function DataIntegrityOverview(props) {

    const [status, setStatus] = useState(false);
    const [filtersResult, setFiltersResult] = useState([]);

    useEffect(() => {
        const fetchData = async () => {
            setStatus(true);
            const result = await Fetch.get(`/v1/pipedrive/filters`, (status, data) => data);
            setFiltersResult(result)
            setStatus(false)
        };

        fetchData();
    }, []);  // eslint-disable-line react-hooks/exhaustive-deps
   


    return (
        <div className="flex flex-col lg:pt-16">
            <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
                    <div className="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
                        <table className="min-w-full divide-y divide-gray-200">
                            <thead className="bg-gray-50">
                                <tr>
                                    
                                    <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                        Filter Name
                                    </th>
                                    <th scope="col" className="relative px-6 py-3">
                                        <span className="sr-only">Actions</span>
                                    </th>
                                </tr>
                            </thead>
                            <tbody className="bg-white divide-y divide-gray-200">
                                {status ? 
                                (
                                    <tr>
                                        
                                        <td className="px-6 py-4 whitespace-nowrap">
                                            <div className="text-sm text-gray-900">Loading ...</div>
                                            <div className="text-sm text-gray-500">Please wait few moments</div>
                                        </td>
                                        <td className="px-6 py-4 whitespace-nowrap text-right text-sm font-medium">
                                            
                                        </td>
                                    </tr>
                                )
                                : filtersResult.map(filter => {
                                    
                                    console.log(filter)
                                    return (
                                        <tr key={filter.id}>
                                            
                                            <td className="px-6 py-4 whitespace-nowrap">
                                                <div className="text-sm text-gray-900">{filter.name}</div>
                                            </td>
                                            
                                            <td className="px-6 py-4 whitespace-nowrap text-right text-sm font-medium">
                                                <Link to={`/data-integrity/${filter.id}`} target="_blank" className="text-indigo-600 hover:text-indigo-900">Check</Link>
                                            </td>
                                        </tr>
                                    )
                                })}


                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    );
}