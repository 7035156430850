import React, { useState, useEffect } from 'react';
import { useParams } from "react-router-dom";
import Fetch from '../../api/Fetch';

export default function DataIntegritySingle(props) {

    const [deals, setDeals] = useState({});
    const [loadingStatus, setLoadingStatus] = useState(true);
    let params = useParams();


    useEffect(() => {
        const fetchData = async () => {
            const result = await Fetch.get(`/v1/pipedrive/data-integrity/${params.filterId}`, (status, data) => data);
            setDeals(result);
            setLoadingStatus(false)
        }; // eslint-disable-line react-hooks/exhaustive-deps
        fetchData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);  // eslint-disable-line react-hooks/exhaustive-deps


    return (
        <div className="flex flex-col lg:pt-16">
            {
                loadingStatus ?
                    (
                        <p>Loading ... </p>
                    ) :
                    <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                        <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
                            <div className="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
                                <table className="min-w-full divide-y divide-gray-200">
                                    <thead className="bg-gray-50">
                                        <tr>
                                            <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                                /
                                            </th>
                                            <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                                PipeDrive
                                            </th>
                                            <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                                HubSpot
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody className="bg-white divide-y divide-gray-200">
                                        {
                                            deals && deals.result.map((deal, index) => (<ResultRow key={index} params={deals.params} pipedrive={deal.pipedrive} hubspot={deal.hubspot} />))
                                        }

                                    </tbody>
                                </table>
                                <div className="lg:pt-16"></div>
                            </div>
                        </div>
                    </div>

            }
        </div >
    );
}



const ResultRow = function (props) {

    return (
        <tr >
            <td className="px-6 py-4 whitespace-nowrap">
                {props.pipedrive.email}
            </td>
            <td className="px-6 py-4  text-sm text-gray-500">
                <div class="" style={{maxWidth:'300px'}}>
                    {props.params.map(property => <p><strong>{property.label}</strong> {props.hubspot[property.hs]}</p>)}
                </div>
            </td>
            <td className="px-6 py-4  text-sm text-gray-500">
                <div class="" style={{maxWidth:'300px'}}>
                    {props.params.map(property => <p><strong>{property.label}</strong> {props.pipedrive[property.pd]}</p>)}
                </div>
            </td>
        </tr>
    )
}