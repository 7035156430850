import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import Fetch from '../api/Fetch';

export default function ABTestsOverview(props) {

    const [landingPages, setLandingPages] = useState([]);
    const [status, setStatus] = useState(false);

    useEffect(() => {
        const fetchData = async () => {
            setStatus(true);
            const result = props.isLandingPage ?  await Fetch.get(`/v1/hubspot/abtests`, (status, data) => data) : await Fetch.get(`/v1/hubspot/abtests/website-pages`, (status, data) => data);
            setLandingPages(result);
            setStatus(false)
        };

        fetchData();
    }, []);  // eslint-disable-line react-hooks/exhaustive-deps
   


    return (
        <div className="flex flex-col lg:pt-16">
            <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
                    <div className="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
                        <table className="min-w-full divide-y divide-gray-200">
                            <thead className="bg-gray-50">
                                <tr>
                                    
                                    <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                        Landing Pages
                                    </th>
                                    <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                        Status
                                    </th>
                                    <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                        Domain
                                    </th>
                                    <th scope="col" className="relative px-6 py-3">
                                        <span className="sr-only">Actions</span>
                                    </th>
                                </tr>
                            </thead>
                            <tbody className="bg-white divide-y divide-gray-200">
                                {status ? 
                                (
                                    <tr>
                                        
                                        <td className="px-6 py-4 whitespace-nowrap">
                                            <div className="text-sm text-gray-900">Loading ...</div>
                                            <div className="text-sm text-gray-500">Please wait few moments</div>
                                        </td>
                                        <td className="px-6 py-4 whitespace-nowrap">
                                            
                                        </td>
                                        <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                                           
                                        </td>
                                        <td className="px-6 py-4 whitespace-nowrap text-right text-sm font-medium">
                                            
                                        </td>
                                    </tr>
                                )
                                : landingPages.map(pages => {
                                    const _page = pages[0]
                                    if (pages.length <= 1) {
                                        return null;
                                    }
                                    return (
                                        <tr key={_page.abTestId}>
                                            
                                            <td className="px-6 py-4 whitespace-nowrap">
                                                <div className="text-sm text-gray-900">{_page.name}</div>
                                                <div className="text-sm text-gray-500">{_page.url}</div>
                                            </td>
                                            <td className="px-6 py-4 whitespace-nowrap">
                                                <span className="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-green-100 text-green-800">
                                                    Active
                                                </span>
                                            </td>
                                            <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                                                {_page.domain}
                                            </td>
                                            <td className="px-6 py-4 whitespace-nowrap text-right text-sm font-medium">
                                                <Link to={`/abtests/${props.isLandingPage ? 'landing-pages' : 'website-pages'}/${_page.abTestId}`} target="_blank" className="text-indigo-600 hover:text-indigo-900">Generate Contacts Report</Link>
                                            </td>
                                        </tr>
                                    )
                                })}


                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    );
}