import React, { useState, useEffect } from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import Dashboard from './pages/Dashboard';
import Login from './pages/Login';
import Header from './components/Header';
import ABTestsPage from './pages/ABTests';
import ABTestPage from './pages/ABTest';
import BlogSanitizerPage from './pages/BlogSanitizer';
import NoMatch from './pages/NoMatch';
import DataIntegrity from './pages/DataIntegrity'
import DataIntegrityOverview from './pages/DataIntegrityOverview'
import Fetch from './api/Fetch';

function getToken() {
  const token = localStorage.getItem('token');
  if(token){
    return token.replace(/^"(.*)"$/, '$1');
  }
  return token;
}
async function verifyLogins(token) {

  return Fetch.post(`/v1/verify-token/`, { 'token': token }, (status, data) => data.status === 'false')

}
function App() {
  const [token, setToken] = useState(getToken())
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    async function fetchData() {
      const tokenStatus = await verifyLogins(token)
      setLoading(tokenStatus)
    }
    fetchData();
    
  }, [token]);

  return (
    <BrowserRouter>

      {
        loading ? (
          <p>Loading ...</p>
        ) :
          (
            token ?
              <div className="wrapper">
                <Header onLogout={setToken} />
                <Routes>
                  <Route path="/" element={<Dashboard />} />
                  <Route path="/abtests/landing-pages" element={<ABTestsPage  isLandingPage={true} title="Landing Pages AB Test" />} />
                  <Route path="/abtests/landing-pages/:abtestId" element={<ABTestPage isLandingPage={true} title="Landing Page AB Test" />} />
                  <Route path="/abtests/website-pages" element={<ABTestsPage isLandingPage={false} title="Website Pages AB Test" />} />
                  <Route path="/abtests/website-pages/:abtestId" element={<ABTestPage  isLandingPage={false} title="Website Page AB Test" />} />
                  <Route path="/data-integrity" element={<DataIntegrityOverview title="Data Integrity Overview" />} />
                  <Route path="/data-integrity/:filterId" element={<DataIntegrity  title="Data Integrity report"/>} />
                
                  <Route path="/blog-sanitizer" element={<BlogSanitizerPage />} />
                  <Route path="*" element={<NoMatch />} />
                </Routes>
              </div>
              :
              <Login onLogin={setToken} />

          )
      }
    </BrowserRouter>

  );
}
export default App;
