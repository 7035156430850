import axios from 'axios';

class Fetch {
   
  constructor() {
    
    let service = axios.create({
      headers: { 'Content-Type': 'application/json'}
    });
    // service.interceptors.response.use(this.handleSuccess, this.handleError);
    this.service = service;
    this.baseURL = 'https://marketing-api.dataguard.de'
    // this.baseURL = 'http://localhost:4567'
  }

  handleSuccess(response) {
    return response;
  }

  handleError = (error) => {
    switch (error.response.status) {
      case 401:
        this.redirectTo(document, '/')
        break;
      case 404:
        this.redirectTo(document, '/404')
        break;
      default:
        this.redirectTo(document, '/500')
        break;
    }
    return Promise.reject(error)
  }

  redirectTo = (document, path) => {
    document.location = path
  }
  
  get(path, callback) {
    return this.service.get(this.baseURL + path).then(
      (response) => callback(response.status, response.data)
    );
  }

  patch(path, payload, callback) {
      
    return this.service.request({
      method: 'PATCH',
      url: this.baseURL + path,
      responseType: 'json',
      data: payload
    }).then((response) => callback(response.status, response.data));
  }

  post(path, payload, callback) {
    const raw_token = localStorage.getItem('token');
    var token = raw_token;
    if(raw_token){
       token = raw_token.replace('"','').replace('"',''); 
    }
    let _payload = {}
    if(token){
        _payload = {...payload, token}
    }else{
        _payload = payload
    }
    return this.service.request({
      method: 'POST',
      url: this.baseURL+ path,
      responseType: 'json',
      data: _payload
    }).then((response) => callback(response.status, response.data));
  }
}

export default new Fetch();