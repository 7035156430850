import React from 'react';
import BlogSanitizer from '../components/BlogSanitizer';
import PageInfo from '../components/PageInfo';
export default function BlogSanitizerPage() {
    return (
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:pt-16">
            <PageInfo title="Blog Sanitizer" subtitle="Remove inline styling and hidden html comments from content" />
            <BlogSanitizer />
        </div>
    );
}