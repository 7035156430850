import React, { useState, useEffect } from 'react';
import { useParams } from "react-router-dom";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Fetch from '../api/Fetch';

export default function ABTestSingle(props) {

    const [abtestDetails, setAbtestDetails] = useState({});
    const [status, setStatus] = useState(true);
    const [datesStatus, setDateStatus] = useState(false);
    const [loadingStatus, setLoadingStatus] = useState(false);
    const [fromDate, setFromDate] = useState(null);
    const [toDate, setToDate] = useState(new Date());
    let params = useParams();

    const fetchData = async () => {
        await setStatus(true);
        setLoadingStatus(true);
        const dateParameters = {
            fromDate: fromDate ? Date.parse(fromDate) : null,
            toDate: toDate ? Date.parse(toDate) : null
        }

        const result = props.isLandingPage ? await Fetch.post(`/v1/hubspot/abtests/${params.abtestId}`, dateParameters, (status, data) => data) :
            await Fetch.post(`/v1/hubspot/website/abtests/${params.abtestId}`, dateParameters, (status, data) => data);

        setAbtestDetails(result);
        setStatus(false)
        setLoadingStatus(false)
    }; // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        const getOverviewData = async () => {

            const result = props.isLandingPage ? await Fetch.post(`/v1/hubspot/abtests/${params.abtestId}/overview`, {}, (status, data) => data) :
                await Fetch.post(`/v1/hubspot/website/abtests/${params.abtestId}/overview`, {}, (status, data) => data)
            setFromDate(new Date(result['fromDate']))
            setDateStatus(true);
        }
        getOverviewData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);  // eslint-disable-line react-hooks/exhaustive-deps


    return (
        <div className="flex flex-col lg:pt-16">
            {datesStatus &&

                <div>
                    <div className="md:grid md:grid-cols-3 md:gap-6">
                        <div className="md:col-span-1">
                            <div className="px-4 sm:px-0">
                                <h3 className="text-lg font-medium leading-6 text-gray-900">Date Range</h3>
                            </div>
                        </div>
                        <div className="mt-5 md:mt-0 md:col-span-2">
                            <div>
                                <div className="shadow sm:rounded-md sm:overflow-hidden">
                                    <div className="px-4 py-5 bg-white space-y-6 sm:p-6">
                                        <div className="grid grid-cols-3 gap-6">
                                            <div className="col-span-3 sm:col-span-2">
                                                <label htmlFor="fromDate" className="block text-sm font-medium text-gray-700"> From Date </label>
                                                <div className="mt-1 flex rounded-md shadow-sm">
                                                    <DatePicker selected={fromDate} onChange={(date) => {
                                                        setFromDate(date)
                                                    }} />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="grid grid-cols-3 gap-6">
                                            <div className="col-span-3 sm:col-span-2">
                                                <label htmlFor="toDate" className="block text-sm font-medium text-gray-700"> To Date </label>
                                                <div className="mt-1 flex rounded-md shadow-sm">
                                                    <DatePicker selected={toDate} onChange={(date) => {
                                                        console.log(date)
                                                        setToDate(date)
                                                    }} />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="px-4 py-3 bg-gray-50 text-right sm:px-6">
                                        <button onClick={fetchData} className="inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">Show Report</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            }
            {
                status ?
                    (
                        loadingStatus && <p>Loading ... </p>
                    ) :
                    <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                        <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
                            <div className="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
                                <table className="min-w-full divide-y divide-gray-200">
                                    <thead className="bg-gray-50">
                                        <tr>
                                            <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                                /
                                            </th>
                                            <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                                {abtestDetails[0].name}
                                            </th>
                                            <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                                {abtestDetails[1].name}
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody className="bg-white divide-y divide-gray-200">
                                        <tr >
                                            <td className="px-6 py-4 whitespace-nowrap">
                                                HubSpot List Link
                                            </td>
                                            <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                                                <a href={`https://app.hubspot.com/contacts/7759810/lists/${abtestDetails[0].listId}`} rel="noreferrer" target="_blank">HubSpot Contacts List</a>
                                            </td>
                                            <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                                                <a href={`https://app.hubspot.com/contacts/7759810/lists/${abtestDetails[1].listId}`} rel="noreferrer" target="_blank">HubSpot Contacts List</a>
                                            </td>
                                        </tr>
                                        <tr >
                                            <td className="px-6 py-4 whitespace-nowrap">
                                                HubSpot Page Edit Link
                                            </td>
                                            <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                                                <a href={`https://app.hubspot.com/content/7759810/edit/${abtestDetails[0].id}/content`} rel="noreferrer" target="_blank">{`https://app.hubspot.com/content/7759810/edit/${abtestDetails[0].id}/content`}</a>
                                            </td>
                                            <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                                                <a href={`https://app.hubspot.com/content/7759810/edit/${abtestDetails[1].id}/content`} rel="noreferrer" target="_blank">{`https://app.hubspot.com/content/7759810/edit/${abtestDetails[1].id}/content`}</a>
                                            </td>
                                        </tr>

                                        <ResultRow label="Page ID" value0={abtestDetails[0].id} value1={abtestDetails[1].id} />
                                        <ResultRow label="URL" value0={abtestDetails[0].url} value1={abtestDetails[1].url} />
                                        <ResultRow label="Page Views" value0={abtestDetails[0].views} value1={abtestDetails[1].views} />
                                        <ResultRow label="Nurture Leads" value0={abtestDetails[0].nurtureLeadsDetailed} value1={abtestDetails[1].nurtureLeadsDetailed} />
                                        <ResultRow label="New Leads" value0={abtestDetails[0].newLeadsDetailed} value1={abtestDetails[1].newLeadsDetailed} />
                                        <ResultRow label="Assigned Leads" value0={abtestDetails[0].assignedLeadsDetailed} value1={abtestDetails[1].assignedLeadsDetailed} />
                                        <ResultRow label="MQLs" value0={abtestDetails[0].mqlsDetailed} value1={abtestDetails[1].mqlsDetailed} />
                                        <ResultRow label="SALs" value0={abtestDetails[0].sqlsDetailed} value1={abtestDetails[1].sqlsDetailed} />
                                        <ResultRow label="Opportunity" value0={abtestDetails[0].opportunityDetailed} value1={abtestDetails[1].opportunityDetailed} />
                                        <ResultRow label="Customers" value0={abtestDetails[0].customerDetailed} value1={abtestDetails[1].customerDetailed} />

                                        
                                        <ResultRow label="New Leads CvR %"
                                            value0={Math.round(10000 * abtestDetails[0].newLeads / abtestDetails[0].views) / 100 + '%'}
                                            value1={Math.round(10000 * abtestDetails[1].newLeads / abtestDetails[1].views) / 100 + '%'}
                                        />
                                        <ResultRow label="Assigned Leads CvR %"
                                            value0={Math.round(10000 * abtestDetails[0].assignedLeads / abtestDetails[0].views) / 100 + '%'}
                                            value1={Math.round(10000 * abtestDetails[1].assignedLeads / abtestDetails[1].views) / 100 + '%'}
                                        />
                                        <ResultRow label="MQL CvR %"
                                            value0={Math.round(10000 * abtestDetails[0].mqls / abtestDetails[0].views) / 100 + '%'}
                                            value1={Math.round(10000 * abtestDetails[1].mqls / abtestDetails[1].views) / 100 + '%'}
                                        />
                                        <ResultRow label="SAL CvR %"
                                            value0={Math.round(10000 * abtestDetails[0].sqls / abtestDetails[0].views) / 100 + '%'}
                                            value1={Math.round(10000 * abtestDetails[1].sqls / abtestDetails[1].views) / 100 + '%'}
                                        />
                                        <ResultRow label="SQO CvR %"
                                            value0={Math.round(10000 * abtestDetails[0].opportunity / abtestDetails[0].views) / 100 + '%'}
                                            value1={Math.round(10000 * abtestDetails[1].opportunity / abtestDetails[1].views) / 100 + '%'}
                                        />
                                        <ResultRow label="New Leads to MQL CvR %"
                                            value0={Math.round(10000 * abtestDetails[0].mqls / abtestDetails[0].newLeads) / 100 + '%'}
                                            value1={Math.round(10000 * abtestDetails[1].mqls / abtestDetails[1].newLeads) / 100 + '%'}
                                        />
                                        <ResultRow label="MQL to SAL CvR %"
                                            value0={Math.round(10000 * abtestDetails[0].sqls / abtestDetails[0].mqls) / 100 + '%'}
                                            value1={Math.round(10000 * abtestDetails[1].sqls / abtestDetails[1].mqls) / 100 + '%'}
                                        />
                                        <ResultRow label="MQL to Opportunity CvR %"
                                            value0={Math.round(10000 * abtestDetails[0].opportunity / abtestDetails[0].mqls) / 100 + '%'}
                                            value1={Math.round(10000 * abtestDetails[1].opportunity / abtestDetails[1].mqls) / 100 + '%'}
                                        />
                                        <ResultRow label="MQL to Customer CvR %"
                                            value0={Math.round(10000 * abtestDetails[0].customer / abtestDetails[0].mqls) / 100 + '%'}
                                            value1={Math.round(10000 * abtestDetails[1].customer / abtestDetails[1].mqls) / 100 + '%'}
                                        />

                                    </tbody>
                                </table>
                                <div className="lg:pt-16"></div>
                                <table className="min-w-full divide-y divide-gray-200 ">
                                    <thead className="bg-gray-50">
                                        <tr>
                                            <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                                {abtestDetails[0].name}
                                            </th>
                                            <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                                Lead Stage
                                            </th>
                                            <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                                Conversion Medium
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody className="bg-white divide-y divide-gray-200">
                                        {
                                            abtestDetails[0].contacts.map((contact) => {
                                                return (
                                                    <tr key={contact.id}>
                                                        <td className="px-6 py-4 whitespace-nowrap">
                                                            <a target="_blank" rel="noreferrer" href={`https://app.hubspot.com/contacts/7759810/contact/${contact.id}`}>{contact.email}</a>
                                                        </td>
                                                        <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                                                            {contact.lifecyclestage}
                                                        </td>
                                                        <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                                                            {contact.conversion_medium}
                                                        </td>
                                                    </tr>

                                                )
                                            })
                                        }
                                    </tbody>
                                    <thead className="bg-gray-50">
                                        <tr >
                                            <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                                {abtestDetails[1].name}
                                            </th>
                                            <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                                Lead Stage
                                            </th>
                                            <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                                Conversion Medium
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody className="bg-white divide-y divide-gray-200">
                                        {
                                            abtestDetails[1].contacts.map((contact) => {
                                                return (
                                                    <tr key={contact.id}>
                                                        <td className="px-6 py-4 whitespace-nowrap">
                                                            <a target="_blank" rel="noreferrer" href={`https://app.hubspot.com/contacts/7759810/contact/${contact.id}`}>{contact.email}</a>
                                                        </td>
                                                        <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                                                            {contact.lifecyclestage}
                                                        </td>
                                                        <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                                                            {contact.conversion_medium}
                                                        </td>
                                                    </tr>
                                                )
                                            })
                                        }
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>

            }
        </div >
    );
}



const ResultRow = function (props) {

    return (
        <tr >
            <td className="px-6 py-4 whitespace-nowrap">
                {props.label}
            </td>
            <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                {props.value0}
            </td>
            <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                {props.value1}
            </td>
        </tr>
    )
}