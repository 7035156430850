import React from 'react';
import PageInfo from '../components/PageInfo';
import DataIntegrityOverviewComponent from '../components/DataIntegrity/Overview';

export default function DataIntegrityOverview(props) {
    return (
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:pt-16">
            <PageInfo title={props.title} subtitle={props.subtitle} />
            <DataIntegrityOverviewComponent  />
        </div>
    );
}